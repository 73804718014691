<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <seguimiento class="ml-5" @reloadProfitCharts="reloadCharts()"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Rentabilidad</h3>
    <!-- OPERACIONES -->
    <div class="graphics-container-cuatriple">
      <div class="tway-violet--text">Operaciones</div>
      <div class="box-container">
        <!-- Digitalizacion Procesos -->
        <div class="inside-box">
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Digitalización de Procesos
              <ToolTips
                width="330"
                description="Cantidad de procesos, funciones y operaciones comerciales en una empresa, que se optimizan al gestionarlas en canales digitales o con herramientas digitales."
                formula="(Ingreso neto por digitalización x 100) / Activos totales"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartDigitizationProcess.series"
              :options="chartDigitizationProcess.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <!-- Integración HD -->
        <div class="inside-box">
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p class="me-4">
              Integración de Herramientas Digitales
              <ToolTips
                width="330px"
                description="Uso de herramientas digitales para mejorar resultados."
                formula="(Ingreso neto por herramientas tecnológicas)  x 100 / Activos totales"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartToolsIntegration.series"
              :options="chartToolsIntegration.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <!-- Migracion nube -->
        <div class="inside-box">
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Migración a la Nube
              <ToolTips
                width="320"
                description="Mover la data y aplicaciones de una empresa desde un servidor privado a uno público para mejorar rendimiento y costos."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartCloudMigration.series"
              :options="chartCloudMigration.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <!-- Optimizacion -->
        <div class="inside-box">
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Optimización
              <ToolTips
                width="330"
                description="Ratio de ganancias o pérdidas obtenidas sobre lo invertido producto de mejoras en logísitca, atención al clientes, modernizaciones, capacitaciones, productividad, etc."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartOpimization.series"
              :options="chartOpimization.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- NEGOCIOS DIGITALES -->
    <div class="graphics-container">
      <div class="tway-violet--text">Negocios Digitales</div>
      <div class="mx-4 mt-1 pt-2 tway-violet--text">
        <p>
          Ingresos por Nuevos Modelos de Negocio
          <ToolTips
            width="330px"
            description="Porcentaje de ingresos atribuibles a nuevos modelos de negocios digitales."
            formula="(Ingresos por nuevos modelos de negocio) /  (Ingresos por modelo de negocio predominantel)"
          />
        </p>
      </div>
      <div>
        <VueApexCharts :series="chartIncome.series" :options="chartIncome.chartOptions" height="290" type="radialBar" />
      </div>
    </div>
    <!-- RETENCION DE CLIENTES -->
    <div class="graphics-container-triple padding-fix">
      <div class="tway-violet--text">Retención de Clientes Digitales</div>
      <div class="box-container">
        <!-- ROI -->
        <div class="inside-box">
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Retorno de la Inversión (ROI)
              <ToolTips
                width="300px"
                description="Beneficio final que recibirá una empresa como resultado de invertir cierta cantidad de recursos en proyectos de retención de clientes digitales."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartReturnInvesment"
              :series="chartReturnInvesment.series"
              :options="chartReturnInvesment.options"
              height="300"
              type="area"
            />
          </div>
        </div>
        <!-- Tasa Retención clientes -->
        <div class="inside-box">
          <div class="mx-8 mt-1 pt-2 tway-violet--text">
            <p>
              Tasa de Retención de Cliente Digital
              <ToolTips
                width="300px"
                description="Ratio que mide la fidelidad de los clientes a un servicio, producto o negocio en un periodo de tiempo de un mes."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartRetentionRate"
              :series="chartRetentionRate.series"
              :options="chartRetentionRate.options"
              height="300"
              type="area"
            />
          </div>
        </div>
        <!-- WinBack -->
        <div class="inside-box">
          <div class="mx-8 mt-1 pt-2 tway-violet--text">
            <p>
              Clientes que Regresan por Acción Digital (Win Back)
              <ToolTips
                width="290px"
                description="Cantidad de ex-clientes o usuarios inactivos que se recuperan como clientes o usuarios activos con iniciativas digitales."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartWinBack"
              :series="chartWinBack.series"
              :options="chartWinBack.options"
              height="300"
              type="area"
            />
          </div>
        </div>
      </div>
      <div class="content-aligment">
        <!-- LTV -->
        <div class="col-6">
          <div>
            <div class="tway-violet--text"></div>
            <div class="mx-8 mt-1 pt-2 tway-violet--text">
              <p class="text-center">
                $ LTV de Cliente Digital
                <ToolTips
                  width="300px"
                  description="Valor neto de los ingresos que genera un cliente digital al negocio durante su tiempo de permanencia como cliente."
                />
              </p>
              <div class="text-sum">Total LTV mes anterior (1): {{ monetaryUnit + lastMonthLTV }}</div>
              <div class="text-sum">Total LTV mes actual (0): {{ monetaryUnit + thisMonthLTV }}</div>
            </div>
            <div>
              <VueApexCharts
                type="heatmap"
                height="300"
                width="600"
                :series="chartDigitalClient.series"
                :options="chartDigitalClient.options"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Seguimiento from "../../components/pmo/profitability/seguimiento.vue";
import ProfitabilityService from "../../services/pmo/profitability/profitabilityService";
import LtvService from "../../services/pmo/ltv/ltvService";
import BudgetService from "../../services/pmo/budget/budgetService";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";

export default {
  name: "Engagement",
  components: {
    Seguimiento,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      chartDigitizationProcess: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319D5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartToolsIntegration: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#16C62E"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartCloudMigration: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7F7F7F"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartOpimization: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#AEEA00"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },

      chartIncome: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 2,
                  opacity: 1,
                  color: "#fff",
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319D5"],
            type: "solid",
          },
          labels: ["%"],
          stroke: { lineCap: "round" },
        },
      },

      chartReturnInvesment: {
        series: [],
        options: {
          chart: {
            height: 450,
            type: "area",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          xaxis: {
            categories: [],
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "%",
                style: { color: "#7319D5" },
              },
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            x: {
              formatter: function (val) {
                return val;
              },
            },
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartRetentionRate: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [0, 2, 5, 2],
            curve: "smooth",
          },
          plotOptions: {
            bar: { columnWidth: "65%" },
          },
          markers: { size: 3 },
          xaxis: {
            categories: [],
          },
          colors: ["#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              seriesName: "%",
              opposite: false,
              axisTicks: { show: true },
              axisBorder: { show: true, color: "#16C62E" },
              title: { text: "%", style: { color: "#16C62E" } },
              labels: {
                style: { color: "#16C62E" },
              },
            },
          ],
          tooltip: {
            shared: true,
            intersect: false,
          },
        },
      },
      chartWinBack: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [0, 2, 5, 2],
            curve: "smooth",
          },
          plotOptions: {
            bar: { columnWidth: "65%" },
          },
          markers: { size: 3 },
          xaxis: {
            categories: [],
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              seriesName: "MM$",
              opposite: false,
              axisTicks: { show: true },
              axisBorder: { show: true, color: "#7F7F7F" },
              title: {
                text: "Cantidad de Clientes",
                style: { color: "#7F7F7F" },
              },
              labels: {
                style: { color: "#7F7F7F" },
              },
            },
          ],
          tooltip: {
            shared: true,
            intersect: false,
          },
        },
      },

      chartDigitalClient: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "heatmap",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            heatmap: {
              radius: 6,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#7F7F7F", "#16C62E", "#7319D5", "#B2B2B2", "#4A148C", "#AEEA00"].reverse(),
          xaxis: {
            type: "category",
            name: "Hola",
            categories: [],
          },
          title: {
            text: undefined,
          },
          grid: {
            padding: {
              right: 20,
            },
          },
        },
      },

      thisMonthLTV: "",
      lastMonthLTV: "",
      monetaryUnit: "",
    };
  },

  methods: {
    reloadCharts() {
      this.callService();
    },
    async callService() {
      await ProfitabilityService.getChartClientRetention(this.project)
        .then((data) => {
          this.chartReturnInvesment.series = data.serie;
          this.$refs.chartReturnInvesment.updateOptions({
            xaxis: {
              categories: data.serie[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));
      await ProfitabilityService.getChartIncomeNewBusinessModel(this.project)
        .then((data) => {
          this.chartIncome.series = data.data;
        })
        .catch((err) => console.dir(err));
      await LtvService.getChartLtv(this.project)
        .then((data) => {
          this.chartDigitalClient.series = data.serie;
        })
        .catch((err) => console.dir(err));
      await ProfitabilityService.getChartDataRisk(this.project)
        .then((data) => {
          this.chartRetentionRate.series = data.retention;
          this.$refs.chartRetentionRate.updateOptions({
            xaxis: {
              categories: data.retention[0].dates,
            },
          });
          this.chartWinBack.series = data.winBack;
          this.$refs.chartWinBack.updateOptions({
            xaxis: {
              categories: data.winBack[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));

      await ProfitabilityService.getChartAnualProfit(this.project)
        .then((data) => {
          this.chartDigitizationProcess.series = data.process.data;
          this.chartToolsIntegration.series = data.integration.data;
          this.chartCloudMigration.series = data.migration.data;
          this.chartOpimization.series = data.opmization.data;
        })
        .catch((err) => console.dir(err));
      await LtvService.getLtvSum(this.project)
        .then((data) => {
          this.thisMonthLTV = data.now;
          this.lastMonthLTV = data.last;
        })
        .catch((err) => {
          console.dir(err);
        });
      await BudgetService.getTableCostCenter(this.project)
        .then((data) => {
          this.monetaryUnit = data.monetaryUnit;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.callService();
  },
};
</script>

<style lang="scss" scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}

.graphics {
  display: flex;
  flex-direction: column;
}

.shrink {
  width: 150px;
}

.bar {
  width: 350px;
}

.resumen {
  display: flex;
  width: 100%;
  height: 250px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
}

.resumen > .resumen-detail {
  width: 17%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.resumen > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumen > .resumen-detail > .resumen-detail-item > div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
}

.resumen > .resumen-detail > .resumen-detail-item > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15%;
}

.resumen > .resumen-bar {
  display: flex;
  width: 60%;
  height: auto;
}

.resumen-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
}

.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.seguimiento {
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
  height: 500px;
}

.seguimiento-costo {
  width: 45%;
  margin: 2px;
}

.seguimiento-periodo {
  width: 45%;
  margin: 2px;
}

.graphics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 20px 20px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 16px;
  }
}

.graphics-container-duo,
.graphics-container-triple,
.graphics-container-cuatriple {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 30px 20px 20px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: row;
  }
  p {
    font-size: 16px;
  }
}

.graphics-container-duo {
  div {
    div {
      display: flex;
      flex-direction: column;
      width: 45%;

      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}

.graphics-container-triple {
  div {
    div {
      width: 32%;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
.graphics-container-cuatriple {
  div {
    div {
      width: 25%;
      display: flex;
      flex-direction: column;
      div {
        width: 101%;
      }

      //border: 1px solid black;
    }
  }
}
.text-sum {
  font-size: 0.8em;
  color: #7f7f7f;
}

.box-container {
  display: flex;
  align-items: stretch;
  width: inherit;
}
.box-container > .inside-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}
.inside-box > div {
  padding-top: auto;
  margin-top: auto;
}
.content-aligment {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.padding-fix {
  padding-bottom: 0px;
}
</style>
