<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1150">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="tway-violet--text accent-4"> mdi-chart-line </v-icon>
          Seguimiento
        </v-btn>
      </template>
      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <h3 class="text-center">Seguimiento Rentabilidad</h3>
            <h4 class="text-center font-weight-regular">Ingresar nueva Rentabilidad</h4>
            <v-btn icon right @click="dialog = false" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- OPERACIONES -->
            <form>
              <div class="d-flex mt-4 justify-space-around">
                <div class="row justify-space-around">
                  <div class="col-12 mt-8">
                    Operaciones
                    <v-divider class="divisor"></v-divider>
                  </div>
                  <div class="col-3">
                    <v-text-field
                      type="text"
                      label="Digitalización de Procesos"
                      v-model="fixedData.processesDigitization"
                      outlined
                    />
                  </div>
                  <div class="col-3">
                    <v-text-field
                      type="text"
                      label="Integración de Herramientas Digitales"
                      v-model="fixedData.digitalToolsIntegration"
                      outlined
                    />
                  </div>
                  <div class="col-3">
                    <v-text-field type="text" label="Migración a la Nube" v-model="fixedData.cloudMigration" outlined />
                  </div>
                  <div class="col-3">
                    <v-text-field type="text" label="Opimización" v-model="fixedData.optimization" outlined />
                  </div>
                </div>
              </div>
            </form>
            <!-- INGRESO -->
            <form>
              <div class="d-flex justify-space-around mt-1">
                <div class="row justify-start">
                  <div class="col-12 mt-8">
                    Negocios Digitales
                    <v-divider class="divisor"></v-divider>
                  </div>
                  <div class="col-4">
                    <v-text-field
                      label="Ingresos por Nuevos Modelos de Negocio"
                      type="text"
                      v-model="fixedData.incomeNewBM"
                      outlined
                    />
                  </div>
                </div>
              </div>
            </form>
            <!-- TABLA PARA INGRESAR DATOS NUEVOS -->
            <h3 class="text-center my-5">Añadir Rentabilidad</h3>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" rowspan="2">Mes / Año</th>
                    <th class="text-center" colspan="3">Retención Clientes Digitales</th>
                    <th class="text-center" rowspan="2"></th>
                  </tr>
                  <tr>
                    <th class="text-center">Retorno Inversión</th>
                    <th class="text-center">Tasa de Retención</th>
                    <th class="text-center">Win Back</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <div>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="true"
                          :return-value.sync="date"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="items.date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="items.date"
                            type="month"
                            no-title
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="items.investment" outlined blur></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="items.retentionRate" outlined blur></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="items.winBack" outlined blur></v-text-field>
                    </td>
                    <td>
                      <div class="text-center d-flex justify-center pa-md-6">
                        <v-btn
                          outlined
                          color="indigo lighten-2"
                          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                          @click.stop="saveProfitTrace(items)"
                        >
                          <div>
                            <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                            <span v-if="!loading.saveStage">Agregar</span>
                            <span v-else>Guardando...</span>
                          </div>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider class="divisor"></v-divider>
            <v-alert
              v-model="alert.saveProfit"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.saveProfit }}
            </v-alert>
            <h4 class="text-center mt-10 font-weight-regular">Listado de Seguimiento Rentabilidad</h4>
            <!-- TABLA LISTADO SEGUIMIENTO RENTABILIDAD -->
            <v-alert
              v-model="alert.updateProfit"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.updateProfit }}
            </v-alert>
            <v-simple-table class="table mt-5">
              <template>
                <thead>
                  <tr>
                    <th class="text-left" scope="col">ID</th>
                    <th class="text-left" scope="col">Fecha</th>
                    <th class="text-left" scope="col">Retorno Inversión</th>
                    <th class="text-left" scope="col">Tasa Retención</th>
                    <th class="text-left" scope="col">Win Back</th>
                    <th class="text-left" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="profitTrace.length > 0"
                    v-for="entry in profitTrace"
                    :key="entry.index"
                    :class="entry.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <th lass="text-center" scope="row">
                      {{ entry.index }}
                    </th>
                    <td class="text-center">
                      <v-menu
                        ref="menu"
                        v-model="entry.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="entry.date"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="entry.date" type="month" no-title scrollable> </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="entry.returnInvestment" outlined></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="entry.retentionRate" outlined></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="entry.winBack" outlined></v-text-field>
                    </td>
                    <td class="text-center buttons">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(entry)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            class="save"
                            @click="updateProfitTrace(entry)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="error" v-bind="attrs" v-on="on" @click="deleteProfitTrace(entry.index)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-alert
              :v-if="alert.fixedData"
              v-model="alert.fixedData"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2 mt-6"
            >
              {{ messageError.fixedData }}
            </v-alert>
            <!-- BOTON GUARDAR -->
            <div class="d-flex mt-6 justify-space-around">
              <div class="row">
                <div class="col-12 text-center justify-center pa-md-5 mx-lg-auto">
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="indigo lighten-2"
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text mx-lg-auto"
                    @click="saveFixedData()"
                  >
                    <v-icon class="tway-violet--text">mdi-content-save</v-icon>
                    Guardar
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
    <confirmation ref="confirm" :message="dialogMessage.title" :message-body="dialogMessage.body"></confirmation>
  </div>
</template>

<script>
import profitabilityService from "../../../services/pmo/profitability/profitabilityService";
import Confirmation from "../utils/ConfirmationDialog.vue";

export default {
  components: { Confirmation },
  data: (vm) => {
    return {
      projectDateProposal: "",
      menu6: false,
      on: false,
      isVisible: false,
      activeClass: "is-visible",
      dialogMessage: {
        title: "",
        body: "",
      },
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      ),
      loading: {
        saveStage: false,
        saveActivity: false,
      },
      alert: {
        fixedData: false,
        saveProfit: false,
        updateProfit: false,
      },
      messageError: {
        fixedData: "",
        saveProfit: "",
        updateProfit: "",
      },
      fixedData: {
        index: "",
        incomeNewBM: "",
        processesDigitization: "",
        digitalToolsIntegration: "",
        cloudMigration: "",
        optimization: "",
      },
      items: {
        investment: "",
        retentionRate: "",
        winBack: "",
        date: new Date().toISOString().substr(0, 7),
      },
      profitTrace: [],
      dialog: false,
      menu: false,
      modal: false,
      startDateProposal: "",
      endDateProposal: "",
      etapas: [],
      combos: {
        selectEtapas: [],
        responsible: [],
      },
      setup: null,
      activities: [],
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    sortedList: function () {
      return this.allScores.slice().sort(function (a, b) {
        return b.score - a.score;
      });
    },
    sortedList2: function () {
      return this.allScor.slice().sort(function (a, b) {
        return b.score - a.score;
      });
    },
    computedStartDateProposal() {
      return this.formatDate(this.setup.startDateProposal);
    },
    // computedStageDateStart() {
    //   return this.formatDate(this.stage.dateStart);
    // },
    // computedStageDateEnd() {
    //   return this.formatDate(this.stage.dateEnd);
    // },
    // computedDateFormatted5() {
    //   return this.formatDate(this.activity.dateStart);
    // },
    // computedDateFormatted6() {
    //   return this.formatDate(this.activity.dateEnd);
    // },
    computedEndDateProposal(val) {
      return val;
    },
  },
  methods: {
    clearForm() {
      this.items.investment = "";
      this.items.retentionRate = "";
      this.items.winBack = "";
    },
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
    },
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadProfitCharts");
      this.saveFixedData();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    saveProject() {},
    saveFixedData() {
      let request = {
        index: this.fixedData.index,
        enterpriseId: this.project.enterpriseId,
        projectId: this.project.projectId,
        incomeNewBM: this.fixedData.incomeNewBM,
        processesDigitization: this.fixedData.processesDigitization,
        digitalToolsIntegration: this.fixedData.digitalToolsIntegration,
        cloudMigration: this.fixedData.cloudMigration,
        optimization: this.fixedData.optimization,
      };
      profitabilityService
        .saveFixedData(request)
        .then(() => {
          this.getFixedData();
          this.dialog = false;
          this.$emit("reloadProfitCharts");
        })
        .catch((err) => {
          console.dir(err);
          let message = err.response.data.message;
          this.alert.fixedData = true;
          if (message == undefined) message = "Debe ingresar un dato numérico correcto.";
          this.messageError.fixedData = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.fixedData = false), 4000);
        });
    },
    getFixedData() {
      profitabilityService
        .getFixedData(this.project)
        .then((data) => {
          this.fixedData = data;
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveUnit = false;
          setTimeout(() => (this.alert.saveUnit = false), 4000);
        });
    },
    saveProfitTrace(items) {
      let request = {
        enterpriseId: this.project.enterpriseId,
        projectId: this.project.projectId,
        returnInvestment: items.investment,
        retentionRate: items.retentionRate,
        winBack: items.winBack,
        date: items.date,
      };
      profitabilityService
        .saveProfitTrace(request)
        .then(() => {
          this.clearForm();
          this.getProfitTrace();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveProfit = true;
          this.messageError.saveProfit = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.saveProfit = false), 4000);
        });
    },
    updateProfitTrace(item) {
      let request = {
        enterpriseId: this.project.enterpriseId,
        projectId: this.project.projectId,
        index: item.index,
        returnInvestment: item.returnInvestment,
        retentionRate: item.retentionRate,
        winBack: item.winBack,
        date: item.date,
      };
      profitabilityService
        .saveProfitTrace(request)
        .then(() => {
          this.clearForm();
          this.saveItem(item);
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.updateProfit = true;
          this.messageError.updateProfit = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.updateProfit = false), 4000);
        });
    },
    getProfitTrace() {
      profitabilityService
        .getProfitTrace(this.project)
        .then((data) => {
          this.profitTrace = data;
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveUnit = false;
          setTimeout(() => (this.alert.saveUnit = false), 4000);
        });
    },
    deleteProfitTrace(entry) {
      profitabilityService
        .deleteProfitTrace(this.project, entry)
        .then(() => {
          this.getProfitTrace();
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveUnit = false;
          setTimeout(() => (this.alert.saveUnit = false), 4000);
        });
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.getFixedData();
    this.getProfitTrace();
    this.clearForm();

    this.reload();
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}
.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}
.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}
.edit_row .v-text-field__details {
  display: none;
}
.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}
.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}
tr.edit_row td {
  height: 60px !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.modalSystem .edit_row .v-input__prepend-outer {
  display: none;
}
.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}
tr.edit_row td .edit {
  display: none;
}
tr.edit_row.is-visible td {
  pointer-events: none;
}
tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
}
tr.edit_row.is-visible td .save {
  display: none !important;
}
tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}
tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}
tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
</style>
